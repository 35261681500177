import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './composants/Header';
import Home from './pages/home';
import Projet from './pages/projet';
import ProjetPresentation from './pages/projet-presentation';



/*React*/
import { gsap } from 'gsap';


function App() {

  


  const [startCount, setStartCount] = useState(0);
  const number = useRef(null);

 

  useEffect(() => {
    let id = setInterval(frame, 14);

    function frame() {
      setStartCount(prevCount => {
        if (prevCount >= 100) {
          clearInterval(id);
          console.log('Lets go');
          return prevCount;

        } else {
          return prevCount + 1;
        }
      });

      const loader = gsap.timeline({defaults: {duration: .6, ease: "none"}});
      loader.to('#loading-bar', {width : '100%', duration: .6});

      gsap.to('#loader', {
        height: "0vh",
        duration: 2,
        ease: "expo.out",
        delay: 3.2,
      });
  
      gsap.to('#loader2', {
        height: "0vh",
        duration: 2,
        ease: "expo.out",
        delay: 3.5,
      });

      gsap.to('#number', {
        top: "-300",
        duration: 2,
        ease: "expo.out",
        delay: 3.5,
      });
      

    }

    


    


    return () => clearInterval(id); // Nettoyage si le composant est démonté
  }, []); // Le tableau vide signifie que l'effet ne s'exécutera qu'une seule fois après le montage du composant


 

  return (
    <Router>
      <div className="App">
        <div id='loader' >
          <p dangerouslySetInnerHTML={{ __html: startCount }} id='number'></p>
          <div id='loading-bar' ></div>
        </div>
        <div id="loader2"></div>
        <div id="container-top">
          <Header />
          <Routes>        
             <Route path='/' element={<Home />} />
            <Route path='/projet/:id' element={<ProjetPresentation />} />
            <Route path='/projet' element={<Projet />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
