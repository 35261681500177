// projet.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './projet.css';
import CardProjet from './card-projet';
import { Outlet } from 'react-router-dom';

import coverProject from '../assets/illustration-projet/projet1.png'
import JPO from '../assets/illustration-projet/JPO.png'

const Projet = () => {
    // Données des projets
    const projects = [
        { id: 1, name: 'JPO Gustave Eiffel', description: "Site pour les Journées Portes Ouvertes de l'IUT Gustave Eiffel à Meaux. Ce site permet aux étudiants de s'inscrire et de centraliser leurs inscriptions dans un back-office, offrant ainsi des statistiques et un export des données au format Excel.", languages: 'Angular, Typescript, PHP, SQL', img: JPO },
        { id: 2, name: 'Pokedex', description: "Le Pokédex, en japonais ポケモン図鑑 (se lisant Pokémon Zukan), signifiant « Encyclopédie Pokémon» est un objet technologique fictif de l'univers Pokémon : il s'agit d'une encyclopédie recensant les créatures fictives connues éponymes. Il permet d'enregistrer les informations sur les Pokémon.", languages: 'HTML, CSS, JS ', img: "https://i.pinimg.com/564x/0e/3b/29/0e3b294beb09239a0625d82a2e2c0561.jpg" },
        { id: 3, name: 'Ancien Portfolio', description: 'Ancien Portfolio', languages: 'HTML, CSS, JS, GSAP', img: coverProject },
        // { id: 3, name: 'Ancien Portfolio', description: 'Ancien Portfolio', languages: 'HTML, CSS, JS, GSAP', img: coverProject },
        
    ];

    const navigate = useNavigate();

    const handleCardClick = (card) => {
        navigate(`/projet/${card.id}`);
    };

    return (
        <div className="container">
            <div id="project-container">
                {projects.map((project) => (
                    <CardProjet 
                        key={project.id}
                        card={project}
                        onCardClick={() => handleCardClick(project)} 
                    />
                ))}
            </div>
            <Outlet />
        </div>
    );
};

export default Projet;
