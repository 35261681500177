// projet-presentation.js

import React from "react";
import { useParams } from "react-router-dom";
import './projet-presentation.css';
import coverProject from '../assets/illustration-projet/projet1.png';
import JPO from '../assets/illustration-projet/JPO.png'
import Pokedex from '../assets/illustration-projet/pokedex.png'

const projectsData = [
    { id: 1, name: 'JPO Gustave Eiffel', description: "Site pour les Journées Portes Ouvertes de l'IUT Gustave Eiffel à Meaux. Ce site permet aux étudiants de s'inscrire et de centraliser leurs inscriptions dans un back-office, offrant ainsi des statistiques et un export des données au format Excel.", languages: 'Angular, Typescript, PHP, SQL', img: JPO, lien: 'https://rayane.eu/' },
    { id: 2, name: 'Pokedex', description: "Le Pokédex, en japonais ポケモン図鑑 (se lisant Pokémon Zukan), signifiant « Encyclopédie Pokémon» est un objet technologique fictif de l'univers Pokémon : il s'agit d'une encyclopédie recensant les créatures fictives connues éponymes. Il permet d'enregistrer les informations sur les Pokémon.", languages: 'HTML, CSS, JS ', img: "https://i.pinimg.com/564x/0e/3b/29/0e3b294beb09239a0625d82a2e2c0561.jpg", lien: 'https://github.com/rayane-dev/Pokedex'},
    { id: 3, name: 'Ancien Portfolio', description: 'Ancien Portfolio', languages: 'HTML, CSS, JS, GSAP', img: coverProject, lien:"https://rayanem-portfolio.000webhostapp.com/" },
    // { id: 4, name: 'Projet 4', description: 'Description du projet 4', languages: 'Java, Spring', img: coverProject },
];

const ProjetPresentation = () => {
    const { id } = useParams();
    const project = projectsData.find(proj => proj.id.toString() === id);

    if (!project) {
        return <p>Projet non trouvé!</p>;
    }

    return (
        <div id="projet-presentation">
            <div className="container">
                <div className="left">
                   <div id="top-left">
                         <div>
                            <h2>{project.name}</h2>
                            <p id="text-explanatory">{project.description}</p>
                        </div>
                        <a href={project.lien}>Lien</a>
                        <div>
                            <h4>Languages utilisés</h4>
                            <p id="language">{project.languages}</p>
                        </div>
                   </div>
                    <div id="bottom-left">
                        <img src={project.img} alt={project.name} />
                    </div>
                </div>
                <div className="right">
                    <a href={project.lien} target="_blank">
                        {/* <img src={project.img} alt="" /> */}
                        <div className="background-presentation" style={{backgroundImage: `url(${project.img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"}}></div>
                    </a>
                    
                </div>
            </div>
        </div>
    );
}

export default ProjetPresentation;
