// card-projet.js

import React from "react";
import './projet.css';
import { Link } from "react-router-dom";

const CardProjet = ({ card }) => {
    return (
        <Link to={`/projet/${card.id}`}>
            <div className="card-project">
                <div>
                    {/* <img src={card.img} alt={card.name} /> */}
                    <div className="background-card" style={{backgroundImage: `url(${card.img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"}}> 
                        {/* Background de la card */ } 
                    </div>
                </div>
                <h3>{card.name}</h3>
            </div>   
        </Link>
    );
}

export default CardProjet;
