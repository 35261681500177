import React, {useState} from "react";
import { Link } from 'react-router-dom';
import './Header.css';
import character from '../assets/character.png';




const Header = () => {

    
const [showMenu, setShowMenu] = useState("false")

const toggleHeader = () => {
    setShowMenu(!showMenu)
}



    return (
        <header className={showMenu ? '' : 'active'} >
            <div className="container">
                <Link to='/'  className="link-title"><h1><img src={character} alt="" srcset="" /> Masmoudi<span>.studio</span></h1></Link>
                <button onClick={toggleHeader}>Menu </button>
            </div>
            <nav>
                <div id="top-nav">
                    <div id="navigation-page">
                        <Link to='/' className="link" onClick={toggleHeader}>Home</Link>
                        <Link to='/projet' className="link" onClick={toggleHeader}>Projet</Link>
                    </div>
                    
                    <div id="navigation-projet">
                        <Link to='/projet/1' className="link" onClick={toggleHeader}><span>01</span>sae 301</Link>
                        <Link to='/projet/2' className="link" onClick={toggleHeader}><span>02</span>Pokedex</Link>
                        <Link to='/projet/3' className="link" onClick={toggleHeader}><span>03</span>Projet 3 -- incomming</Link>
                        <Link to='/projet/4' className="link" onClick={toggleHeader}><span>04</span>Projet 3 -- incomming</Link>
                    </div>
                </div>

                <div id="bottom-nav">
                    <p>[fermer le menu]</p>
                </div>
            </nav>

        
        </header>
    )
}






export default Header;