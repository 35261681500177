import React from 'react';
import { useNavigate } from 'react-router-dom';

import './home.css';
import mainPicture from '../assets/main_picture.png';
import homeBackground from '../assets/background_home.png';

import Footer from '../composants/Footer';
import { Link } from 'react-router-dom';
import SimpleParallax from "simple-parallax-js";
import coverProject from '../assets/illustration-projet/projet1.png';
import JPO from '../assets/illustration-projet/JPO.png';


/*CARD*/
import card1 from '../assets/card1.png'

const Home = () => {
    const projects = [
        { id: 1, name: 'JPO Gustave Eiffel', description: "Site Journée Porte Ouverte IUT Gustave Eiffel.", languages: 'Angular, Typescript, PHP, SQL', img: card1, lien: '/projet/1' },
        { id: 2, name: 'Pokedex', description: 'Encyclopédie de Pokemon ', languages: 'HTML, CSS, JS ', img: "https://images.alphacoders.com/581/581224.jpg", lien: '/projet/2' },
        { id: 3, name: 'Ancien Portfolio', description: 'Ancien Portfolio (Rayane Masmoudi)', languages: 'HTML, CSS, JS, GSAP', img: coverProject,  lien: '/projet/3' },
        // { id: 3, name: 'Ancien Portfolio', description: 'Ancien Portfolio', languages: 'HTML, CSS, JS, GSAP', img: coverProject },
    ];

    const navigate = useNavigate();

    const handleCardClick = (project) => {
        if (project.lien) {
            navigate(project.lien);
        }
    };

    return (
        <div>
            <div className="home">
                <div className="container">
                    <main>
                        <div id="top_main">
                            <p>Salut ! je suis Rayane et je batis des choses pour internet. <br />
                            Ici on parle de dev design et tout un tas de chose.</p>
                        </div>
                        <div id='bottom_main'>
                            <p>rayane.masmoudi03@gmail.com</p>
                            <img src={mainPicture} alt="" />
                        </div>
                    </main>
                </div>
            </div>
            <div className="home2">
                <div className="container">
                    <div id="content_about">
                        <h3>About</h3>
                        <div className="paragraph">
                            <p>Je suis web-designer et développeur web  de sites Web. J'aime travailler sur la conception de maquettes, le code des pages internet et l’animation des élément de mes sites. Je travaille dur et régulièrement, mais j’aime aussi parler et partager.</p>
                            <p>Dans les prochaines années j'aimerai bien travailler sur la blockchain et en paralelle continuer a développer des interface web en me renforcant aussi sur le back-end</p>
                            <p>Je suis un apprenant permanent, j’aime développement web, le MMA, la blockchain et d’apprendre sur tout ce qui me permet d’être plus productif.</p>
                        </div>
                    </div>
                </div>

                <SimpleParallax>
                    <img src={homeBackground} scale={1.4} delay={.8} alt="" id="backgroundParalax" />
                </SimpleParallax>

                <div className="container">
                    <div id="content_about2">
                        <h5>“Les bons artistes copient, les grands artistes volent !”</h5>
                        <div>
                            <div className="paragraph">
                                <p>Sur cette page vous pouvez retrouver plusieurs projet designer et dev par moi même. Certains sont de travaux d'école d'autre sont des projets personels.</p>
                                <p>Chaque projets a pour but de montrer ma créativiter et les différentes techno que je maitrise tels que HTML, CSS, JS, Angular, React, PHP. </p>
                                <p>Plusieurs projets arriveront prochainement avec React Node JS et mongo DB</p>
                            </div>
                        </div>
                    </div>
                    <div id="selectedWork">
                        <div className="top-work">
                            <h3>Selected Work</h3>
                            <a><Link to='/projet'>See all works</Link></a>
                        </div>
                        <div id="content-work">
                            <div className="left">
                                {projects.slice(0, 2).map(project => (
                                    <div key={project.id} className="card" onClick={() => handleCardClick(project)}>
                                        <div className="card-picture" style={{ backgroundImage: `url(${project.img})`, backgroundRepeat: "no-repeat", backgroundSize: "cover",backgroundPosition: 'center center' }}>
                                        </div>
                                        <div className="card-bottom">
                                            <p className="title-card">{project.name}</p>
                                            <p className="subtitle">{project.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="right">
                                {projects.slice(2).map(project => (
                                    <div key={project.id} className="card" onClick={() => handleCardClick(project)}>
                                        <div className="card-picture" style={{ backgroundImage: `url(${project.img})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                        </div>
                                        <div className="card-bottom">
                                            <p className="title-card">{project.name}</p>
                                            <p className="subtitle">{project.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
